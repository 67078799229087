import React from "react";
import { componentProps } from "../baseComponent";
import "./pillarInfo.scss";
import { InView } from "react-intersection-observer";

import classNames from "classnames";

type Props = componentProps & {
  pillars: React.ReactNode[];
};

type State = {};

export class PillarInfo extends React.Component<Props, State> {
  state = {};

  render() {
    const { pillars } = this.props;
    return (
      <div className="PillarInfo_root">
        <div className="PillarInfo_content">
          {pillars.map((pillar) => {
            return (
              <InView triggerOnce threshold={0.4}>
                {({ inView, ref, entry }) => (
                  <div
                    ref={ref}
                    className={classNames("PillarInfo_pillar", {
                      slideInBottom: inView,
                    })}
                  >
                    {pillar}
                  </div>
                )}
              </InView>
            );
          })}
        </div>
      </div>
    );
  }
}
