import React from "react";
import { ImageBanner } from "../../../components/imageBanner/imageBanner";
import "./services.scss";
import * as SERVICES_INFO from "../../../content/pages/services.json";
import { marked } from "marked";

export class Services extends React.Component<{}, {}> {
  componentDidMount() {
    //   const markdown = require("../../../content/site" + name);
  }

  render() {
    const data = SERVICES_INFO;
    const markdownParsed = marked.parse(data.body);

    return (
      <div className="Services_root">
        <ImageBanner src={data.thumbnail} title={data.title} />
        <div
          className="Services_content"
          dangerouslySetInnerHTML={{ __html: markdownParsed }}
        ></div>
      </div>
    );
  }
}
