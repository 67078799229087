import classNames from "classnames";
import { createValidImageURL } from "data/Utils";
import React from "react";
import { componentProps } from "../baseComponent";
import "./imageBanner.scss";

type Props = componentProps & {
  src?: string;
  title?: string;
  description?: string;
};

type State = {};

export class ImageBanner extends React.Component<Props, State> {
  state = {};

  render() {
    const { rootclassname, modifiers, src, title, description } = this.props;

    let source = createValidImageURL(src);

    return (
      <div className={classNames("ImageBanner_root", rootclassname, modifiers)}>
        <div
          className={classNames("ImageBanner_image", modifiers)}
          style={{
            backgroundImage: `url(${source})`,
          }}
        />
        <div className={classNames("ImageBanner_text", modifiers)}>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}
