import parseMD from "parse-md";
import React from "react";
import { ArticleTile } from "../../../components/articleList/articleList";
import { ImageBanner } from "../../../components/imageBanner/imageBanner";
import { CaseStudy } from "../../../data/DataTypes";
import "./caseStudies.scss";
import * as CASE_STUDIES_INFO from "../../../content/pages/caseStudies.json";
import { marked } from "marked";

type CaseStudiesState = {
  caseStudies: CaseStudy[];
};

export class CaseStudies extends React.Component<{}, CaseStudiesState> {
  componentDidMount() {
    const markdownFiles = require.context(
      "content/caseStudies",
      false,
      /\.\/.*md/i
    );

    const dataPromises: Promise<boolean>[] = [];
    const caseStudies: CaseStudy[] = [];

    markdownFiles.keys().forEach((markdownFile) => {
      const name = markdownFile.split(".", 2)[1] + ".md";

      const markdown = require("content/caseStudies" + name);

      // push each "fetch" of an article to a array of promises. We then listen for when all these promises are finished later, to set them to state.
      dataPromises.push(
        fetch(markdown)
          .then((response) => {
            // fetch markdown delivers the text of the markdown
            return response.text();
          })
          .then((text) => {
            // using parse-md library to split the text from the "metadata" at top of markdown.
            const { metadata, content } = parseMD(text);

            // push each metadata / content as a single BlogPost object to an array of BlogPosts.
            caseStudies.push({
              ...(metadata as CaseStudy),
              content,
              id: markdownFile,
            });
            return true;
          })
          .catch((e) => {
            // this should never happen... but you know
            // console.log(e);
            return false;
          })
      );
    });

    Promise.all(dataPromises).then(() => {
      caseStudies.sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      });

      this.setState({ caseStudies });
    });
  }

  render() {
    if (!this.state || !this.state.caseStudies) return;

    const data = CASE_STUDIES_INFO;

    const markdownParsed = marked.parse(data.body);
    return (
      <div className="CaseStudies_root">
        <ImageBanner src={data.thumbnail} title={data.title} />

        <div
          className="CaseStudies_content"
          dangerouslySetInnerHTML={{ __html: markdownParsed }}
        ></div>
        <div className="CaseStudies_casesContainer">
          <div className="CaseStudies_cases">
            {this.state.caseStudies.map((c) => {
              return <ArticleTile {...c} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}
