import React from "react";
import { Banner } from "../../../components/banner/banner";
import "./homepage.scss";
import * as HOME_INFO from "../../../content/site/home.json";
import parseMD from "parse-md";
import { CaseStudy, CoreValue } from "../../../data/DataTypes";
import { InstagramPost } from "../../../components/instagramFeeder/instagramFeeder";
import YoutubeIFrame from "../../../components/youtubeIframe/youtubeIframe";
import { PillarInfo } from "../../../components/pillarInfo/pillarInfo";
import { createValidImageURL } from "data/Utils";
import * as SITE_SETTINGS from "content/site/config.json";
import { InView } from "react-intersection-observer";
import classNames from "classnames";

type State = {
  coreValues: CoreValue[];
  caseStudies: CaseStudy[];
  instagramTitle: string;
  instagramFeedPost: InstagramPost[];
};

export class Homepage extends React.Component<{}, State> {
  async componentDidMount() {
    // TODO Put all of this data getting into a data.ts file so its all self contained.
    const caseStudyMarkdownFiles = require.context(
      "content/caseStudies",
      false,
      /\.\/.*md/i
    );

    const coreValuesFiles = require.context(
      "content/coreValues",
      false,
      /\.\/.*md/i
    );

    const caseStudiesPromises: Promise<boolean>[] = [];
    const caseStudies: CaseStudy[] = [];

    caseStudyMarkdownFiles.keys().forEach((markdownFile) => {
      const name = markdownFile.split(".", 2)[1] + ".md";
      const markdown = require("content/caseStudies" + name);

      // push each "fetch" of an article to a array of promises. We then listen for when all these promises are finished later, to set them to state.
      caseStudiesPromises.push(
        fetch(markdown)
          .then((response) => {
            // fetch markdown delivers the text of the markdown
            return response.text();
          })
          .then((text) => {
            // using parse-md library to split the text from the "metadata" at top of markdown.
            const { metadata, content } = parseMD(text);

            // push each metadata / content as a single BlogPost object to an array of BlogPosts.
            caseStudies.push({
              ...(metadata as CaseStudy),
              content,
              id: markdownFile,
            });
            return true;
          })
          .catch((e) => {
            // this should never happen... but you know
            //  console.log(e);
            return false;
          })
      );
    });

    const coreValuesPromises: Promise<boolean>[] = [];
    const coreValues: CoreValue[] = [];

    coreValuesFiles.keys().forEach((markdownFile) => {
      const name = markdownFile.split(".", 2)[1] + ".md";
      const markdown = require("content/coreValues" + name);

      coreValuesPromises.push(
        fetch(markdown)
          .then((response) => {
            // fetch markdown delivers the text of the markdown
            return response.text();
          })
          .then((text) => {
            // using parse-md library to split the text from the "metadata" at top of markdown.
            const { metadata } = parseMD(text);

            // push each metadata / content as a single BlogPost object to an array of BlogPosts.
            coreValues.push({
              ...(metadata as CoreValue),
            });
            return true;
          })
          .catch((e) => {
            // this should never happen... but you know
            //  console.log(e);
            return false;
          })
      );
    });

    Promise.all(caseStudiesPromises).then(() => {
      caseStudies.sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      });

      const f = caseStudies.splice(0, 3);

      this.setState({ caseStudies: f });
    });

    Promise.all(coreValuesPromises).then(() => {
      coreValues.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      this.setState({ coreValues });
    });
  }

  render() {
    const homeData = HOME_INFO;
    const config = SITE_SETTINGS;

    if (!this.state || !this.state.caseStudies || !this.state.coreValues)
      return;
    return (
      <div className="Homepage_root">
        <YoutubeIFrame src={homeData.video}>
          <InView triggerOnce threshold={0.4}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className={classNames("Homepage_title")}>
                <div
                  style={{ animationDelay: "0.3s" }}
                  className={classNames("Homepage_titleInner", {
                    slideInBottom: inView,
                  })}
                >
                  <div
                    className={classNames(
                      "Homepage_logoContainer aboveNotchLarge alt"
                    )}
                  >
                    <img
                      className="Homepage_logo"
                      src={createValidImageURL(config.logo)}
                      alt="Grove Group"
                    />
                  </div>
                  <h3 className="Homepage_descripton bold">
                    We Deliver{" "}
                    <span className="Homepage_textRotate">
                      <span className="Homepage_innerText">
                        <span className="electrical">Electrical</span>
                        <br />
                        <span className="compliance">Compliance</span>
                        <br />
                        <span className="automation">Automation</span>
                      </span>
                    </span>
                  </h3>
                  <br />
                  <p>{homeData.headerdescription}</p>
                </div>
              </div>
            )}
          </InView>
        </YoutubeIFrame>

        <div className="Homepage_content">
          <br />
          <h4 className="center">{homeData.coreValuesHeader}</h4>
          <PillarInfo
            pillars={this.state.coreValues.map((cv) => {
              return (
                <>
                  <img src={createValidImageURL(cv.cover)} alt={cv.title} />
                  <h5>{cv.title}</h5>
                  <p>{cv.description}</p>
                </>
              );
            })}
          />
          <Banner modifiers="alt2 alignCenter marginBottom">
            <p>{homeData.quoteOne}</p>
          </Banner>
          </div>
      </div>
    );
  }
}
