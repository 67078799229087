import React from "react";
import { ImageBanner } from "../../../components/imageBanner/imageBanner";
import "./company.scss";
import * as ABOUT_INFO from "../../../content/pages/about.json";
import { marked } from "marked";

export class Company extends React.Component<{}, {}> {
  componentDidMount() {
    //   const markdown = require("../../../content/site" + name);
  }

  render() {
    const data = ABOUT_INFO;

    const markdownParsed = marked.parse(data.body);
    return (
      <div className="Company_root">
        <ImageBanner src={data.thumbnail} title={data.title} />
        <div
          className="Company_content"
          dangerouslySetInnerHTML={{ __html: markdownParsed }}
        ></div>
      </div>
    );
  }
}
