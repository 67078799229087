import React from "react";
import { ImageBanner } from "../../../components/imageBanner/imageBanner";
import "./caseStudy.scss";
import { marked } from "marked";
import parseMD from "parse-md";
import { CaseStudy } from "data/DataTypes";

type Props = {
  caseStudyId: string;
};

type State = {
  caseStudyInfo: CaseStudy;
};

export class CaseStudyPage extends React.Component<Props, State> {
  componentDidMount() {
    const markdown = require("content/caseStudies/" +
      this.props.caseStudyId.split("./")[1]);

    fetch(markdown)
      .then((response) => {
        // fetch markdown delivers the text of the markdown
        return response.text();
      })
      .then((text) => {
        // using parse-md library to split the text from the "metadata" at top of markdown.
        const { metadata, content } = parseMD(text);

        this.setState({
          caseStudyInfo: {
            ...(metadata as CaseStudy),
            content,
            id: this.props.caseStudyId,
          },
        });

        return true;
      })
      .catch((e) => {
        // this should never happen... but you know
        console.log(e);
        return false;
      });
  }

  render() {
    if (!this.state || !this.state.caseStudyInfo) return;

    const { caseStudyInfo } = this.state;

    const markdownParsed = marked.parse(caseStudyInfo.content);

    return (
      <div className="CaseStudy_root">
        <ImageBanner src={caseStudyInfo.cover} title={caseStudyInfo.title} />
        <div
          className="CaseStudy_content"
          dangerouslySetInnerHTML={{ __html: markdownParsed }}
        ></div>
      </div>
    );
  }
}
