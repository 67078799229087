import React from "react";
import "./Footer.scss";
import { Tab } from "../../components/Tab/tab";
import * as CONTACT_DETAILS from "content/site/contactDetails.json";
import * as SITE_SETTINGS from "content/site/config.json";
import { createValidImageURL } from "data/Utils";
import { SocialMedia } from "components/socialMedia/socialMedia";

type State = {
  hover: boolean;
};

class Footer extends React.Component<{}, State> {
  state = {
    hover: false,
  };

  render() {
    const contactDetails = CONTACT_DETAILS;
    const config = SITE_SETTINGS;
    return (
      <section className="Footer_root">
        <a href="http://fplreflib.findlay.co.uk/images/pdf/beeas/beeas-2014-winners.pdf">
          <img
            className="Footer_blogo"
            src={createValidImageURL(config.logo)}
            alt="grove group logo"
          />
        </a>
        <div className="Footer_menu">
          <Tab modifiers={"alt"} label="Home" to="/Home" />
{/*           <Tab modifiers={"alt"} label="About Us" to="/About" /> */}
          <Tab modifiers={"alt"} label="Services" to="/Services" />
{/*           <Tab modifiers={"alt"} label="Case Studies" to="/CaseStudies" /> */}
          <Tab modifiers={"alt"} label="Contact Us" to="/Contact" />
        </div>
        <div className="Footer_content">
          <div className="Footer_socials">
            <h5>Our Socials</h5>
            <SocialMedia />
          </div>
          <div className="Footer_address">
            <h5>Address</h5>
            <h6>{contactDetails.address}</h6>
            <h6>
              {contactDetails.phoneNumber} | {contactDetails.email}
            </h6>
          </div>
        </div>
        <h6>
          <a href="/privacyPolicy">Privacy Policy</a> | Website made and
          maintained by <a href="https://www.tomfranklin.dev/">Tom Franklin</a>{" "}
        </h6>
      </section>
    );
  }

  private onFooterClick = () => {
    window.open("https://www.dota2.com/home");
  };
  private onHover = () => {
    this.setState({ hover: true });
  };
  private onLeaveHover = () => {
    this.setState({ hover: false });
  };
}

export default Footer;
