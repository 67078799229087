import { convertStringToValidURL } from "data/Utils";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./Footer/Footer";
import Header from "./header/header";
import { CaseStudies } from "./views/caseStudies/caseStudies";
import { CaseStudyPage } from "./views/caseStudy/caseStudy";
import { Company } from "./views/company/company";
import { Contact } from "./views/Contact/contact";
import { Homepage } from "./views/homepage/homepage";
import { PrivacyPolicy } from "./views/privacyPolicy/privacyPolicy";
import { Services } from "./views/services/services";

type State = {
  fileKeys: string[];
};

export class App extends React.Component<{}, State> {
  componentDidMount() {
    const markdownFiles = require.context(
      "content/caseStudies",
      false,
      /\.\/.*md/i
    );

    this.setState({
      fileKeys: markdownFiles.keys(),
    });
  }

  render() {
    if (!this.state) return;

    return (
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Home" element={<Homepage />} />
            <Route path="/About" element={<Company />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/CaseStudies" element={<CaseStudies />} />
            <Route path="/Contact" element={<Contact />} />
            {this.state.fileKeys.map((key) => {
              const urlifiedString = convertStringToValidURL(key);

              return (
                <Route
                  path={"/CaseStudies/" + urlifiedString}
                  element={<CaseStudyPage caseStudyId={key} />}
                />
              );
            })}
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}
